
import { Component, Vue, Prop } from 'vue-property-decorator'
import Echarts from 'echarts'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import { MapList } from '@/types/pressureMonitor'

@Component
export default class PressureMonitorMap extends Vue {
  private onlineImg = require('@/assets/icon/pressureMonitor/online.svg')
  private onlineImgCursor = require('@/assets/icon/pressureMonitor/online2.svg')
  private offImg = require('@/assets/icon/pressureMonitor/offline.svg')
  private offImgCursor = require('@/assets/icon/pressureMonitor/offline2.svg')
  private alarmImg = require('@/assets/icon/pressureMonitor/alarm.svg')
  private alarmImgCursor = require('@/assets/icon/pressureMonitor/alarm2.svg')
  private map: AMap.Map | any = null
  private drawer = false
  private drawerData: MapList = {
    // 水压
    sampleData: '',
    // 水压告警
    warningSample: '',
    deviceStatus: '',
    deviceTypeName: '',
    deviceNumber: ''
  }

  get projectId () {
    return this.$route.params.projectId as string || ''
  }

  created () {
    this.getProjectLocation()
  }

  // 获取项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.$nextTick(() => {
        this.loadMap(res.projectInfo, res.projectLocation)
      })
    })
  }

  getData () {
    this.$axios.get(this.$apis.pressureMonitor.selectTopsailPressureMap, {
      projectId: this.projectId
    }).then(res => {
      this.onlineEchart({ normalAmount: res.normalAmount, warnAmount: res.warnAmount })
      // 将设备marker添加到地图上
      res.devices.map((item: any) => {
        const lnglat = [Number(item.longitude), Number(item.latitude)]
        const marker: any = new AMap.Marker({
          icon: new AMap.Icon({
          // 图标的取图地址
            image: this.iconStatus(item, false)
          }),
          offset: new AMap.Pixel(-18, -48),
          position: new AMap.LngLat(lnglat[0], lnglat[1])
        })
        marker.detail = item
        marker.on('click', (e: any) => {
          // 判断点击的是否是当前抽屉的设备
          if (this.drawerData.deviceId !== e.target.detail.deviceId) {
            if (this.drawer) {
              this.drawerClose()
            }
            this.drawer = true
            this.drawerData = JSON.parse(JSON.stringify(e.target.detail))
            e.target.setOffset(new AMap.Pixel(-22, -64))
            e.target.setIcon(new AMap.Icon({
              // 图标的取图地址
              image: this.iconStatus(item, true)
            }))
          }
        })
        this.map.add(marker)
      })
    })
  }

  // 加载地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      this.map = map
      this.map.on('click', this.drawerClose)
      // 绘制
      drawPolygon(this.map, list || [], null, this.drawerClose)
      this.getData()
    })
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      // 将图标替换
      for (let i = 0; i < this.map.getAllOverlays('marker').length; i++) {
        if (this.drawerData.deviceId === this.map.getAllOverlays('marker')[i].detail.deviceId) {
          this.map.getAllOverlays('marker')[i].setOffset(new AMap.Pixel(-18, -48))
          this.map.getAllOverlays('marker')[i].setIcon(this.iconStatus(this.map.getAllOverlays('marker')[i].detail, false))
          break
        }
      }
      // 清空抽屉
      Object.keys(this.drawerData).forEach((item: string) => {
        this.drawerData[item] = ''
      })
    }
  }

  // 处理图标状态 bigShow:是否大图标
  iconStatus (data: any, bigShow: boolean) {
    let icon
    if (data.deviceStatus === '1') {
      icon = data.runStatus === '1' ? (bigShow ? this.alarmImgCursor : this.alarmImg) : (bigShow ? this.onlineImgCursor : this.onlineImg)
    } else if (data.deviceStatus === '2') {
      icon = bigShow ? this.offImgCursor : this.offImg
    }
    return icon
  }

  // 环形图
  onlineEchart (presentPie: { normalAmount: string; warnAmount: string }) {
    const pieEchart: any = Echarts.init(this.$refs.onlineEcharts as any)
    const option = {
      title: {
        text: '告警统计',
        textStyle: {
          align: 'center',
          color: '#000',
          fontSize: 14,
          fontWeight: 'bold'
        },
        top: 20,
        left: 20
      },
      color: ['#1B85FF', '#FF341B'],
      legend: {
        icon: 'circle',
        itemHeight: 6,
        show: true,
        itemGap: 15,
        right: 20,
        top: '45%',
        orient: 'vertical',
        textStyle: {
          fontSize: 14 // 字体大小
        },
        formatter: (item: string) => {
          return item === '正常' ? `${item}  ${presentPie.normalAmount}` : `${item}  ${presentPie.warnAmount}`
        }
      },
      series: [{
        name: 'Line 1',
        type: 'pie',
        clockWise: true,
        radius: ['35%', '60%'],
        center: ['30%', '58%'],
        itemStyle: {
          normal: {
            label: {
              show: false
            },
            labelLine: {
              show: false
            }
          }
        },
        hoverAnimation: false,
        data: [{
          name: '正常',
          value: presentPie.normalAmount
        }, {
          name: '告警',
          value: presentPie.warnAmount
        }]
      }]
    }
    pieEchart.setOption(option)
  }

  getState (val: string) {
    switch (val) {
      case '1':
        return '正常'
      case '2':
        return '低压告警'
      case '3':
        return '高压告警'
      default:
        return '--'
    }
  }

  // 历史数据
  toHistory () {
    this.$router.push({
      name: 'pressureMonitorMapHistory',
      params: { deviceId: this.drawerData.deviceId || '', projectId: this.projectId },
      query: {
        deviceNumber: this.drawerData.deviceNumber
      }
    })
  }

  // 页面离开
  destroyed () {
    if (this.map) {
      this.map.off('click', this.drawerClose)
      this.map.destroy()
    }
  }
}
